import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { InfosService } from '@app/services/infos.service';
import { RolesService } from '@app/services/roles.service';
import { TasksService } from '@app/services/tasks.service';
import { apiUrl } from '@environments/env';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-commercial-template',
  templateUrl: './commercial-template.component.html',
  styleUrls: ['./commercial-template.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class CommercialTemplateComponent implements OnInit {

  countUnread;
  caseId;
  case;
  state;
  caseForm: FormGroup;
  piecesList;
  pieceEdit;
  piecesAdd;
  rdvs;
  titrePiece = '';
  currentPiece = '';
  userRole;
  arrayRdvs;
  readOnly;
  mobile = false
  showBar = true
  mobileChoice;
  panelColor = new FormControl('red');
  caseSignee = false;
  date = new Date();
  actualDate;

  task;

  showUnread = true;
  showRead = true;
  infos;
  infosRead = [];
  infosUnread = [];
  eventsSubject: Subject<void> = new Subject<void>();

  products = []

  emitEventToChild() {
    this.eventsSubject.next();
  }
  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private router: Router,
    public sRoles: RolesService,
    private fb: FormBuilder,
    private sInfos: InfosService,
    private sTasks: TasksService,
    private sHistoriques: HistoriqueService,
    private http: HttpClient,
    private _location: Location,
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.caseId = this.route.snapshot.params['id'];
    if (this.router.getCurrentNavigation().extras.state) {
      this.currentPiece = this.router.getCurrentNavigation().extras.state.currentPiece;
      if (this.router.getCurrentNavigation().extras.state.value === 'piecesList') {
        this.piecesList = true;
      }
      else if (this.router.getCurrentNavigation().extras.state.value === 'piecesAdd') {
        this.piecesAdd = true;
      }
      else if (this.router.getCurrentNavigation().extras.state.value === 'pieceEdit') {
        this.pieceEdit = true;
      }
      if (this.router.getCurrentNavigation().extras.state.mobileChoice) {
        this.mobileChoice = true;

      }
    }
    else {
      this.rdvs = true;
    }
    this.userRole = this.sRoles.userRole;
    this.showCaseById().then(res => {
      this.arrayRdvs = this.case.rdvs;
      this.state = this.case.state;
      this.caseForm = this.fb.group({
        qualification: [],
        isHidden: [],
        state: '',
        rdv_state: this.fb.group({
          'status': [],
          'why': [],
        }),

      });
    });
    this.date.setHours(this.date.getHours()+1)
    this.actualDate = this.date.toISOString()

  }

   ngOnInit() {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    this.showInfosByAdvId()
    this.getGroups();
    }

  showCaseById() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      if(this.case.state == "Signée"){
        this.caseSignee = true
      }
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }


  showPiecesList() {
    this.piecesList = true;
    this.rdvs = false;
    this.pieceEdit = false;
    this.piecesAdd = false;
  }
  showRdvs() {
    this.piecesList = false;
    this.rdvs = true;
    this.pieceEdit = false;
    this.piecesAdd = false;
  }
  showPiecesAdd() {
    this.piecesList = false;
    this.rdvs = false;
    this.pieceEdit = false;
    this.piecesAdd = true;
  }

  saveChange(reason,change,value) {
    if(this.case.state == "Signée"){
      this.caseForm.value.datesign = this.actualDate
      this.caseForm.value.qualification = 'qualifié'
      this.caseForm.value.rdv_state.status = 'Oui'

      const advs = ["6135c7036457510004c2eef1", "5ff22a8ba0f2570004f151d0"]
      advs.forEach(element => {
        let comment = {
          comment : 'signée',
          reason : 'signée',
          created_by : this.sRoles.user.id,
          created_at : new Date(),
          caseId: this.caseId,
          customerId: this.case.customer,
          adv: element
        }
        this.sInfos.createInfo(comment).then(res => {
          if (res['error']) {
            alert(res.error);
          }
        }).catch(err =>{
          console.log(err)
        });
      });

      const taskPrime = {
            message: 'Créer le dossier MPR pour ' + this.case.customer.name,
            commentary: '',
            state: '1-Urgente',
            date: this.actualDate,
            created_at: this.actualDate,
            created_by: this.sRoles.user.id,
            caseId: this.caseId,
            customerId: this.case.customer._id,
            target: '61b083b09593180004b58149',
            tag: 'Dossier MPR'
        };

        this.sTasks.CreateTask(taskPrime).then(res => {
            if (res['error']) {
                alert(res.error);
            } else {
                console.log('Tâche créée avec succès pour le service comptable.');
            }
        }).catch(err => {
            console.log(err);
        });

      const task =  {
        message: 'Attribuer le dossier de ' + this.case.customer.name + ' à une ADV' ,
        commentary: '',
        state: '1-Urgente',
        date: this.actualDate ,
        created_at : this.actualDate,
        created_by : "5f76e5503926a01790428179",
        target: '5ff22a8ba0f2570004f151d0',
        tag: 'dossier'
      }
       this.sTasks.CreateTask(task).then(res => {
        if (res['error']) {
          alert(res.error);
        }
      });
    }

    else if (this.case.state == "Négociation") {
      this.caseForm.value.qualification = 'qualifié'
    }
    else if (this.case.state == "Non Signée" && this.caseForm.value.rdv_state.status === 'Oui') {
      this.caseForm.value.qualification = 'En attente';
      this.caseForm.value.isHidden = true;
      const task =  {
        message: 'Qualifier le rdv ' + this.case.customer.name.trim(),
        commentary: '',
        state: '1-Urgente',
        date: this.actualDate ,
        created_at : this.actualDate,
        created_by : "5f76e5503926a01790428179",
        caseId: this.caseId,
        customerId: this.case.customer._id,
        // ID de Brice
        target: '5f77416ab0286e4dbc4a9d6f',
        //target: "5f76e5503926a01790428179"
        tag: null
      }
       this.sTasks.CreateTask(task).then(res => {
        if (res['error']) {
          alert(res.error);
        }
      });
    }
    else if (this.case.state == "Non Signée" && this.caseForm.value.rdv_state.status === 'Non porte' || this.case.state == "Non Signée" && this.caseForm.value.rdv_state.status === 'Non refus visite' || this.case.state == "Non Signée" && this.caseForm.value.rdv_state.status === '') {
      this.caseForm.value.qualification = 'non qualifié'
      this.caseForm.value.isHidden = true;
    }
    else if (this.caseForm.value.rdv_state.status === 'Non porte' || this.caseForm.value.rdv_state.status === 'Non refus visite' || this.caseForm.value.rdv_state.status === 'Non pas trouvé' || this.caseForm.value.rdv_state.status === 'Non annulé') {
      this.caseForm.value.qualification = 'non qualifié'
    }
    else if(this.case.state == "Annulée"){
      this.caseForm.value.datecancel = this.actualDate
      this.caseForm.value.qualification = 'qualifié'
    }

    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });

    this.sCases.editCase(this.caseForm.value, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.showCaseById();
    });
  }

  changeState(reason, change, value){
      if(this.case.kits_vendus != undefined) {

      if(this.case.kits_vendus.startsWith('['))

        if(this.case.kits_vendus != null && this.case.kits_vendus.startsWith('[')) {
        JSON.parse(this.case.kits_vendus).forEach(element => {
          if(element.active) {
            this.products.push(element.name)
          }
        });

        if(value === 'Signée') {
          if(this.case.r1[0].debrief === '') {
            alert('Vous devez débriefer votre R1 et renseigner le kit vendu avant de changer le statut de l\'affaire')
            this.showCaseById();
          }
          else if(this.products.length === 0) {
            alert('Vous devez débriefer votre R1 et renseigner le kit vendu avant de changer le statut de l\'affaire')
            this.showCaseById();
          }
          else {
            if ( confirm( "En passant au statut signée vous ne pourrez plus modifier les données rentrées, Continuer?" ) ) {
              document.getElementById("openModalButton").click();

              this.caseSignee = true
              this.readOnly = true;
              this.saveChange(reason, change, value)
              }
            else {
              this.caseForm.value.state = 'Prospection'
            }
          }
        }

        else if(value === 'Non Signée') {
            if(this.case.r1[0].debrief === '') {
              alert('Vous devez débriefer votre R1 avant de passer l\'affaire en Non Signée')
              this.showCaseById();
            }
            else {
              this.saveChange(reason, change, value)
            }
          }
        else{
          this.saveChange(reason, change, value)
        }

        }
      }
      else {
        if(value === 'Non Signée') {
          if(this.case.r1[0].debrief === '') {
            alert('Vous devez débriefer votre R1 avant de passer l\'affaire en Non Signée')
            this.showCaseById();
          }
          else {
            this.saveChange(reason, change, value)
          }
        }
      }

  }

  showComment(){
    this.showBar = !this.showBar
  }

  showInfosByAdvId() {
    this.sInfos.getInfoByAdvId(this.sRoles.user.id).subscribe(res => {
      this.infos = res;
      this.infos.forEach(element => {
         if(element.vu) {
          this.infosRead.push(element)
        }
        else {
          this.infosUnread.push(element)
        }
      });
    })
  }
async getGroups(): Promise<void> {
  try {
    const countUnread: any = await this.http.get(apiUrl.apiUrl + '/messages/count-unread').toPromise();
    this.countUnread = countUnread;
  } catch (error) {  }
}


  goBack() {
    this._location.back();
  }

}
