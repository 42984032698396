import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { PdfsService } from '@app/services/pdfs.service';
import html2canvas from 'html2canvas';

import { InfosService } from '@app/services/infos.service';
import { PiecesService } from '@app/services/pieces.service';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-sign-modal-tech',
  templateUrl: './sign-modal-tech.component.html',
  styleUrls: ['./sign-modal-tech.component.scss'],
})
export class SignModalTechComponent implements AfterViewInit {
  isEmpty = true;

  capturedImage;
  loading = false;

  isValid = false;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  pieceForNotif: Array<any>;
  recetteTitle;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sCases: CasesService,
    private sPdfs: PdfsService,
    private dialogRef: MatDialog,
    private router: Router,
    private sPieces: PiecesService,
    private sInfos: InfosService,
    private sb: MatSnackBar
  ) {
    this.data = data;
  }

  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 1);
  }

  dispatch() {
    this.isValid = true;
    if (this.data.titlePiece === 'Bon de réception') {
      this.createReception();
    } else if (this.data.titlePiece === 'Bon de livraison') {
      this.createLivraison();
    } else if (this.data.titlePiece === 'bi') {
      this.createBi();
    } else if (this.data.titlePiece === 'bi_cm') {
      this.createBiCm();
    } else {
      this.createRecette();
    }
  }

  async createLivraison() {
    const kit = this.data.kit.filter((k) => k.livraison === 'en cours');
    html2canvas(document.querySelector('#captureTech')).then((canvas) => {
      this.capturedImage = canvas.toDataURL('image/jpeg');

      this.sPdfs
        .generateLivraison(
          'livraison',
          this.data.case,
          kit,
          this.data.sign,
          this.capturedImage,
          this.data.chantierId
        )
        .then((res) => {
          if (res === 'ok') {
            console.log(res);
            kit.forEach((element) => {
              this.getCurrentKitAndSaveChange(true, 'livraison', element);
            });
            this.loading = true;
            setTimeout(() => {
              this.closeModale();
              this.sb.open(`Bon de livraison généré avec succès`, '', {
                duration: 2000,
                verticalPosition: 'bottom',
                panelClass: ['add-snackbar'],
              });
              this.router.navigate([
                'technicien-case-detail',
                this.data.chantierId,
              ]);
            }, 3000);
          }
        });
    });
  }

  async createBi() {
    console.log('dans createBi pour sign modal tech', this.data);
    html2canvas(document.querySelector('#captureTech')).then((canvas) => {
      this.capturedImage = canvas.toDataURL('image/jpeg');
      this.sPdfs
        .generateBi(
          'bi',
          this.data.case,
          this.data.kit,
          this.data.sign,
          this.capturedImage,
          this.data.chantierId
        )
        .then((res) => {
          if (res === 'ok') {
            console.log(res);
            this.loading = true;
            setTimeout(() => {
              this.closeModale();
              this.advNotif('bi');
              this.sb.open(`Bon d'intervention généré avec succès`, '', {
                duration: 2000,
                verticalPosition: 'bottom',
                panelClass: ['add-snackbar'],
              });
              this.router.navigate([
                'technicien-case-detail',
                this.data.chantierId,
              ]);
            }, 3000);
          }
        });
    });
  }
  async createBiCm() {
    console.log('dans createBiCm pour sign modal tech', this.data);
    html2canvas(document.querySelector('#captureTech')).then((canvas) => {
      this.capturedImage = canvas.toDataURL('image/jpeg');
      this.sPdfs
        .generateBiCm(
          'bi_cm',
          this.data.case,
          this.data.kit,
          this.data.sign,
          this.capturedImage,
          this.data.chantierId,
          this.data.form
        )
        .then((res) => {
          if (res === 'ok') {
            console.log(res);
            this.loading = true;
            setTimeout(() => {
              this.closeModale();
              this.advNotif('bi');
              this.sb.open(`Bon d'intervention généré avec succès`, '', {
                duration: 2000,
                verticalPosition: 'bottom',
                panelClass: ['add-snackbar'],
              });
              this.router.navigate([
                'technicien-case-detail',
                this.data.chantierId,
              ]);
            }, 3000);
          }
        });
    });
  }
  async createReception() {
    const kit = this.data.kit.filter((k) => k.reception === 'en cours');
    html2canvas(document.querySelector('#captureTech')).then((canvas) => {
      this.capturedImage = canvas.toDataURL('image/jpeg');

      this.sPdfs
        .generateReception(
          'livraison',
          this.data.case,
          kit,
          this.data.sign,
          this.capturedImage,
          this.data.chantierId
        )
        .then((res) => {
          if (res === 'ok') {
            console.log(res);
            kit.forEach((element) => {
              this.getCurrentKitAndSaveChange(true, 'reception', element);
            });
            this.loading = true;
            setTimeout(() => {
              this.closeModale();
              this.sb.open(`Bon d'intervention généré avec succès`, '', {
                duration: 2000,
                verticalPosition: 'bottom',
                panelClass: ['add-snackbar'],
              });
              this.router.navigate([
                'technicien-case-detail',
                this.data.chantierId,
              ]);
            }, 3000);
          }
        });
    });
  }

  async createRecette() {
    html2canvas(document.querySelector('#captureTech')).then((canvas) => {
      this.capturedImage = canvas.toDataURL('image/jpeg');

      this.sPdfs
        .generateRecette(
          this.data.chantierId,
          this.data.titlePiece,
          this.data.case,
          this.data.kit,
          this.data.sign,
          this.capturedImage
        )
        .then((res) => {
          if (res === 'ok') {
            console.log(res);
            this.loading = true;
            setTimeout(() => {
              this.closeModale();
              this.checkRecettePieces(this.data.titlePiece);
              this.advNotif('recetteAdv');
              this.sb.open(`Recette générée avec succès`, '', {
                duration: 2000,
                verticalPosition: 'bottom',
                panelClass: ['add-snackbar'],
              });
              this.router.navigate([
                'technicien-case-detail',
                this.data.chantierId,
              ]);
            }, 3000);
          }
        });
    });
  }

  async checkRecettePieces(titlePiece) {
    if (
      titlePiece === 'recette_pv_micro_onduleur_facade' ||
      titlePiece === 'recette_pv_micro_onduleur_sol' ||
      titlePiece === 'recette_pv_micro_onduleur_surimposition' ||
      titlePiece === 'recette_pv_micro_onduleur_integration' ||
      titlePiece === 'recette_pv_onduleur_facade' ||
      titlePiece === 'recette_pv_onduleur_sol' ||
      titlePiece === 'recette_pv_onduleur_surimposition' ||
      titlePiece === 'recette_pv_onduleur_integration'
    ) {
      this.recetteTitle = 'Recette Panneaux solaires';
      this.pieceForNotif = [];

      if (
        titlePiece === 'recette_pv_onduleur_facade' ||
        titlePiece === 'recette_pv_onduleur_sol' ||
        titlePiece === 'recette_pv_onduleur_surimposition' ||
        titlePiece === 'recette_pv_onduleur_surimposition'
      ) {
        titlePiece = 'recette_pv_onduleur';
      } else {
        titlePiece = 'recette_pv_micro_onduleur';
      }
      this.sPieces
        .getPiecesByChantierIdAndCible(this.data.chantierId, titlePiece)
        .subscribe((res) => {
          console.log(res);
          const pieces: any = res;

          if (!pieces.find((e) => e.title === 'Champ photovoltaïque')) {
            this.pieceForNotif.push('Champ photovoltaïque');
          }
          if (!pieces.find((e) => e.title === 'Toîture globale')) {
            this.pieceForNotif.push('Toîture globale');
          }
          if (!pieces.find((e) => e.title === 'Coffret AC/DC')) {
            this.pieceForNotif.push('Coffret AC/DC');
          }
          if (!pieces.find((e) => e.title === 'Pièces onduleurs')) {
            this.pieceForNotif.push('Pièces onduleurs');
          }
          if (!pieces.find((e) => e.title === 'Coffret de sécurité')) {
            this.pieceForNotif.push('Coffret de sécurité');
          }
          if (!pieces.find((e) => e.title === 'Onduleur')) {
            this.pieceForNotif.push('Onduleur');
          }
          this.sendNotif();
        });
    }
    if (titlePiece === 'recette_pacae') {
      this.recetteTitle = 'Recette PACAE';
      this.pieceForNotif = [];

      this.sPieces
        .getPiecesByChantierIdAndCible(this.data.chantierId, 'recette_pacae')
        .subscribe((res) => {
          console.log(res);
          const pieces: any = res;

          if (!pieces.find((e) => e.title === 'Tuyau et calorifugeage')) {
            this.pieceForNotif.push('Tuyau et calorifugeage');
          }
          if (!pieces.find((e) => e.title === 'Sortie des condensats')) {
            this.pieceForNotif.push('Sortie des condensats');
          }
          if (!pieces.find((e) => e.title === 'Photo unité extérieure')) {
            this.pieceForNotif.push('Photo unité extérieure');
          }
          if (!pieces.find((e) => e.title === 'Réseau comble aéroliques')) {
            this.pieceForNotif.push('Réseau comble aéroliques');
          }
          if (!pieces.find((e) => e.title === 'Photo unité intérieure')) {
            this.pieceForNotif.push('Photo unité intérieure');
          }
          if (!pieces.find((e) => e.title === 'Disjoncteur')) {
            this.pieceForNotif.push('Disjoncteur');
          }
          this.sendNotif();
        });
    }
    if (
      titlePiece === 'recette_pacaa_gainable' ||
      titlePiece === 'recette_pacaa_split'
    ) {
      this.recetteTitle = 'Recette PACAA';
      this.pieceForNotif = [];
      this.sPieces
        .getPiecesByChantierIdAndCible(this.data.chantierId, titlePiece)
        .subscribe((res) => {
          console.log(res);
          const pieces: any = res;

          if (!pieces.find((e) => e.title === 'Tuyau et calorifugeage')) {
            this.pieceForNotif.push('Tuyau et calorifugeage');
          }
          if (!pieces.find((e) => e.title === 'Sortie des condensats')) {
            this.pieceForNotif.push('Sortie des condensats');
          }
          if (!pieces.find((e) => e.title === 'Photo unité extérieure')) {
            this.pieceForNotif.push('Photo unité extérieure');
          }
          if (!pieces.find((e) => e.title === 'Réseau comble aéroliques')) {
            this.pieceForNotif.push('Réseau comble aéroliques');
          }
          if (!pieces.find((e) => e.title === 'Photo unité intérieure')) {
            this.pieceForNotif.push('Photo unité intérieure');
          }
          if (!pieces.find((e) => e.title === 'Disjoncteur')) {
            this.pieceForNotif.push('Disjoncteur');
          }
          this.sendNotif();
        });
      // sortir les pieces
    }
    if (titlePiece === 'recette_adoucisseur') {
      this.recetteTitle = 'Recette Adoucisseur';

      this.pieceForNotif = [];
      this.sPieces
        .getPiecesByChantierIdAndCible(
          this.data.chantierId,
          'recette_adoucisseur'
        )
        .subscribe((res) => {
          console.log(res);
          const pieces: any = res;

          if (!pieces.find((e) => e.title === 'Etiquette test PH')) {
            this.pieceForNotif.push('Etiquette test PH');
          }

          if (!pieces.find((e) => e.title === 'Filtre adoucisseur')) {
            this.pieceForNotif.push('Filtre adoucisseur');
          }

          if (!pieces.find((e) => e.title === 'Disjoncteur')) {
            this.pieceForNotif.push('Disjoncteur');
          }
          this.sendNotif();
        });
    }
    if (titlePiece === 'recette_bt_monobloc') {
      this.recetteTitle = 'Recette BT monobloc';
      this.pieceForNotif = [];

      this.sPieces
        .getPiecesByChantierIdAndCible(
          this.data.chantierId,
          'recette_bt_monobloc'
        )
        .subscribe((res) => {
          console.log(res);
          const pieces: any = res;
          if (!pieces.find((e) => e.title === 'Ballon thermodynamique')) {
            this.pieceForNotif.push('Ballon thermodynamique');
          }
          if (
            !pieces.find((e) => e.title === 'Passage en batiment et liaison')
          ) {
            this.pieceForNotif.push('Passage en batiment et liaison');
          }
          if (!pieces.find((e) => e.title === 'Disjoncteur')) {
            this.pieceForNotif.push('Disjoncteur');
          }
          if (!pieces.find((e) => e.title === 'Liaison frigorifique')) {
            this.pieceForNotif.push('Liaison frigorifique');
          }

          this.sendNotif();
        });
    }
    if (titlePiece === 'recette_bt_split') {
      this.recetteTitle = 'Recette BT Split';
      this.pieceForNotif = [];

      this.sPieces
        .getPiecesByChantierIdAndCible(this.data.chantierId, 'recette_bt_split')
        .subscribe((res) => {
          console.log(res);
          const pieces: any = res;
          if (!pieces.find((e) => e.title === 'Ballon thermodynamique')) {
            this.pieceForNotif.push('Ballon thermodynamique');
          }
          if (
            !pieces.find((e) => e.title === 'Passage en batiment et liaison')
          ) {
            this.pieceForNotif.push('Passage en batiment et liaison');
          }
          if (!pieces.find((e) => e.title === 'Disjoncteur')) {
            this.pieceForNotif.push('Disjoncteur');
          }
          if (!pieces.find((e) => e.title === 'Liaison frigorifique')) {
            this.pieceForNotif.push('Liaison frigorifique');
          }
          if (!pieces.find((e) => e.title === 'Unité extérieure BT')) {
            this.pieceForNotif.push('Unité extérieure BT');
          }
          this.sendNotif();
        });
    }

    if (titlePiece === 'recette_vmc') {
      this.recetteTitle = 'Recette VMC';
      this.pieceForNotif = [];

      this.sPieces
        .getPiecesByChantierIdAndCible(this.data.chantierId, 'recette_vmc')
        .subscribe((res) => {
          console.log(res);
          const pieces: any = res;
          if (!pieces.find((e) => e.title === 'Unité intérieure VMC')) {
            this.pieceForNotif.push('Unité intérieure VMC');
          }
          if (!pieces.find((e) => e.title === 'Circuit gaine air vicié VMC')) {
            this.pieceForNotif.push('Circuit gaine air vicié VMC');
          }
          if (!pieces.find((e) => e.title === 'Disjoncteur VMC')) {
            this.pieceForNotif.push('Disjoncteur VMC');
          }
          if (!pieces.find((e) => e.title === 'Cablage électrique VMC')) {
            this.pieceForNotif.push('Cablage électrique VMC');
          }
          if (!pieces.find((e) => e.title === 'Circuit gaine air neuf VMC')) {
            this.pieceForNotif.push('Circuit gaine air neuf VMC');
          }
          this.sendNotif();
        });
    }
    if (titlePiece === 'recette_tableau_electrique') {
      this.recetteTitle = 'Recette Tableau electrique';
      this.pieceForNotif = [];

      this.sPieces
        .getPiecesByChantierIdAndCible(
          this.data.chantierId,
          'recette_tableau_electrique'
        )
        .subscribe((res) => {
          console.log(res);
          const pieces: any = res;
          if (!pieces.find((e) => e.title === 'Tableau electrique')) {
            this.pieceForNotif.push('Tableau electrique');
          }
          this.sendNotif();
        });
    }
  }

  sendNotif() {
    if (this.pieceForNotif.length > 0) {
      let info = {
        comment:
          'La ' +
          this.recetteTitle +
          ' a été créée sans les photos: ' +
          this.pieceForNotif.join(),
        reason: 'recette',
        created_by: '5f76e5503926a01790428179',
        created_at: new Date(),
        caseId: this.data.case,
        customerId: this.data.case.customer._id,
        adv: '5fd3a4ae9c80b54f88c95c28',
      };
      this.sInfos.createInfo(info).then((res) => {
        console.log(res);
        if (res['error']) {
          alert(res.error);
        }
      });
    }
  }

  advNotif(reason) {
    const comment =
      reason === 'recetteAdv'
        ? ` La ${this.recetteTitle} a été créée`
        : "Le bon d'intervention a été créé";

    const advIds = ['6135c7036457510004c2eef1', '6315a265798f340004b04f0d'];

    advIds.forEach((advId) => {
      const info = {
        comment: comment,
        reason: reason,
        created_by: '5f76e5503926a01790428179',
        created_at: new Date(),
        caseId: this.data.case,
        customerId: this.data.case.customer._id,
        adv: advId,
      };

      this.sInfos.createInfo(info).then((res) => {
        console.log(`Notification envoyée à l'ADV ${advId}`, res);
        console.log(res);
        if (res['error']) {
          alert(res.error);
        }
      });
    });
    }
  async signature() {
    await html2canvas(document.querySelector('#captureTech')).then((canvas) => {
      this.capturedImage = canvas.toDataURL('image/jpeg');
      this.sPdfs
        .generateSignature(this.data.sign, this.capturedImage)
        .then((res) => {
          console.log(res);
        });
    });
  }

  getCurrentKitAndSaveChange(event, key, currentKit) {
    currentKit[key] = event;
    const array = JSON.parse(this.data.case.kits_vendus);
    const index = array.findIndex((item) => item.name === currentKit.type);

    array[index] = currentKit;
    this.data.case.kits_vendus = JSON.stringify(array);

    this.sCases.editCase(this.data.case, this.data.case._id).then((res) => {});
  }

  closeModale() {
    this.dialogRef.closeAll();
  }
}
