import { TasksService } from './../../services/tasks.service';
import { UsersService } from '@app/services/users.service';

import { RolesService } from '@app/services/roles.service';
import { HistoriqueService } from '@app/services/historique.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { ChantierService } from '@app/services/chantier.service';
import { PiecesService } from '@app/services/pieces.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { apiUrl } from '@environments/env';
import { InfosService } from '@app/services/infos.service';

@Component({
  selector: 'app-technicien-case-detail',
  templateUrl: './technicien-case-detail.component.html',
  styleUrls: ['./technicien-case-detail.component.scss'],
})
export class TechnicienCaseDetailComponent implements OnInit {
  caseId;
  case;
  caseDate;
  pieces;
  vt;
  techPieces;
  showPieceList = false;
  showPvList = true;
  chantier;
  chantierId;
  mobile;
  user;
  pieceForm: FormGroup;
  timeOutLoader = true;
  multipleImagesPieces;
  piecesOk;
  apiUrl = apiUrl.apiUrl + '/piece/';
  recette;
  pvLivraison;
  pvReception;
  showBar = true;
  bonInter;

  infos;
  infosRead = [];
  infosUnread = [];
  chantiers;
  lastDate = '2000-02-20T16:02:16.000Z';
  lastChantier = false;
  date = new Date();
  actualDate;
  showSavDeDietriechPacae = false;
  showSavDeDietriechPacaa = false;
  showSavDeDietriechBt = false;
  techs;
  skipSignature: boolean;

  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private sPieces: PiecesService,
    private router: Router,
    private sChantier: ChantierService,
    private AuthenticationService: AuthenticationService,
    private fb: FormBuilder,
    private http: HttpClient,
    private sHistoriques: HistoriqueService,
    private sRoles: RolesService,
    private sInfos: InfosService,
    private sChantiers: ChantierService,
    private sUsers: UsersService,
    private sTasks: TasksService
  ) {
    this.date.setHours(this.date.getHours() + 1);
    this.actualDate = this.date.toISOString();
  }

  ngOnInit(): void {
    this.AuthenticationService.user.subscribe((x) => (this.user = x));

    if (window.screen.width <= 1100) {
      this.mobile = true;
    }
    this.skipSignature =
      JSON.parse(localStorage.getItem('skipSignature')) || false;

    this.chantierId = this.route.snapshot.params['id'];
    this.ShowChantierById();
    this.showInfosByAdvId();
    let now = new Date();

    this.pieceForm = this.fb.group({
      title: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      qualification: new FormControl('En attente de qualification'),
      created_by: new FormControl(this.sRoles.user.id),
      commentary: new FormControl(''),
      created_at: new FormControl(now.setHours(now.getHours())),
      chantier: new FormControl(this.chantierId),
      updated_at: new FormControl(),
      chef: new FormControl(),
    });

    this.sUsers.getAllTechs().subscribe((techs) => {
      this.techs = techs['users'];
    });
  }

  onCheckboxChange() {
    localStorage.setItem('skipSignature', JSON.stringify(this.skipSignature));
  }
  showCaseById(caseId) {
    return new Promise((resolve) => {
      this.sCases.getCaseById(caseId).subscribe(
        (res) => {
          this.case = res[0];
          if (this.case.kits_vendus && this.case.kits_vendus.startsWith('[')) {
            JSON.parse(this.case.kits_vendus).forEach((produit) => {
              if (produit.name === 'PAC Air/Eau' && produit.active) {
                this.showSavDeDietriechPacae = true;
              } else if (produit.name === 'PAC Air/Air' && produit.active) {
                this.showSavDeDietriechPacaa = true;
              } else if (
                produit.name === 'Ballon thermodynamique' &&
                produit.active
              ) {
                this.showSavDeDietriechBt = true;
              }
            });
          }
          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  ShowChantierById() {
    this.sChantier.getChantierById(this.chantierId).subscribe((res) => {
      this.chantier = res[0];
      this.showCaseById(this.chantier.caseId._id);
      this.ShowPieceByCaseId();
      this.showChantierByCaseId();
    });
  }
  showChantierByCaseId() {
    this.sChantiers
      .getChantierByCaseId(this.chantier.caseId._id)
      .subscribe((res) => {
        this.chantiers = res;
        this.chantiers.forEach((element) => {
          if (element.date > this.lastDate) {
            this.lastDate = element.date;
          }
        });
        if (this.lastDate === this.chantier.date) {
          this.lastChantier = true;
        }
      });
  }

  ShowPieceByCaseId() {
    return new Promise((resolve) => {
      this.sPieces.getTechPiecesByCaseId(this.chantier.caseId._id).subscribe(
        (res) => {
          this.techPieces = res;

          if (this.user.role === 'Technicien') {
            this.techPieces = this.techPieces.filter(
              (piece) => piece.title != 'facture'
            );
          }
          this.FindTechPiece();
          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }
  FindTechPiece() {
    this.techPieces.forEach((element) => {
      if (element.title == 'VT') {
        this.vt = element;
      } else if (
        element.title == "Bon d'inter" &&
        element.description.includes(this.chantier.title)
      ) {
        this.bonInter = element;
      } else if (
        element.title == 'Recette' &&
        element.description.includes(this.chantier.title)
      ) {
        this.recette = element;
      } else if (
        element.title == 'PV livraison' &&
        element.description.includes(this.chantier.title)
      ) {
        this.pvLivraison = element;
      } else if (
        element.title == 'PV réception' &&
        element.description.includes(this.chantier.title)
      ) {
        this.pvReception = element;
      }
    });
  }

  GoToDetailPiece(pieceId) {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['detail-piece', pieceId]);
  }

  goToAddPiece() {
    this.router.navigate(['/add-piece', this.chantier.caseId._id], {
      state: { title: this.chantier.title, id: this.chantierId },
    });
  }

  toggleShowPieceList() {
    this.showPvList = false;
    this.showPieceList = !this.showPieceList;
  }
  toggleShowPvList() {
    this.showPieceList = false;
    this.showPvList = !this.showPvList;
  }

  saveChangeChef(chantier, caseId, reason, change) {
    const value = chantier.chef;
    this.sChantier.editChantier(chantier, caseId).then((res) => {
      if (res['error']) {
        return res.error;
      }
    });
    this.sHistoriques
      .createHistorique(
        this.chantier.caseId,
        this.case.customer._id,
        reason,
        change,
        value,
        this.sRoles.user.id
      )
      .then((res) => {
        if (res['error']) {
          alert(res);
        }
      });
  }

  saveChangeEquipe(chantier, caseId, reason, change) {
    const value = chantier.title;
    this.sChantier.editChantier(chantier, caseId).then((res) => {
      if (res['error']) {
        return res.error;
      }
    });
    this.sHistoriques
      .createHistorique(
        this.chantier.caseId,
        this.case.customer._id,
        reason,
        change,
        value,
        this.sRoles.user.id
      )
      .then((res) => {
        if (res['error']) {
          alert(res);
        }
      });
  }

  saveChange(chantier, caseId, reason, change) {
    const value = chantier.description;
    this.sChantier.editChantier(chantier, caseId).then((res) => {
      if (res['error']) {
        return res.error;
      }
    });
    this.sHistoriques
      .createHistorique(
        this.chantier.caseId,
        this.case.customer._id,
        reason,
        change,
        value,
        this.sRoles.user.id
      )
      .then((res) => {
        if (res['error']) {
          alert(res);
        }
      });
  }

  saveChangeState(chantier, chantierId, reason, change) {
    if (this.chantier.statut === 'Terminé') {
      if (
        this.chantier.description === '' ||
        this.chantier.description === undefined ||
        this.chantier.description === null ||
        this.chantier.chef === '' ||
        this.chantier.chef === undefined ||
        this.chantier.chef === null
      ) {
        alert(
          "Assurez vous d'avoir bien renseigné le débrief, le chef d'équipe et ajouté les pièces nécessaires avant de terminer le chantier"
        );
        this.ShowChantierById();
      } else {
        chantier.updated_at = new Date();
        const value = chantier.statut + ' ' + chantier.title;
        this.sChantier.editChantier(chantier, chantierId).then((res) => {
          if (res['error']) {
            return res.error;
          }
        });
        this.sChantier
          .getLastChantier(this.chantier.caseId._id)
          .subscribe((lastChantier) => {
            if (lastChantier[0]._id == this.chantier._id) {
              let comment = {
                comment: 'statut du chantier : ' + chantier.statut,
                reason: 'chantier',
                created_by: this.sRoles.user.id,
                created_at: new Date(),
                caseId: this.chantier.caseId._id,
                customerId: this.case.customer,
                adv: this.sUsers.compta[0]._id,
              };
              this.sInfos.createInfo(comment).then((res) => {
                if (res['error']) {
                  alert(res.error);
                }
              });
              const task = {
                message:
                  'Facturer le chantier de ' +
                  this.chantier.caseId.customer.name.trim(),
                commentary: '',
                state: '1-Urgente',
                date: this.actualDate,
                created_at: this.actualDate,
                created_by: '5f76e5503926a01790428179',
                caseId: this.chantier.caseId._id,
                customerId: this.case.customer._id,
                target: this.sUsers.compta[0]._id,
                tag: null,
              };
              this.sTasks.CreateTask(task).then((res) => {
                if (res['error']) {
                  alert(res.error);
                }
              });
              this.sHistoriques
                .createHistorique(
                  this.chantier.caseId,
                  this.case.customer._id,
                  reason,
                  change,
                  value,
                  this.sRoles.user.id
                )
                .then((res) => {
                  if (res['error']) {
                    alert(res);
                  }
                });
            }
          });
      }
    } else {
      chantier.updated_at = new Date();
      const value = chantier.statut + ' ' + chantier.title;
      this.sChantier.editChantier(chantier, chantierId).then((res) => {
        if (res['error']) {
          return res.error;
        }
      });
      let comment = {
        comment: 'statut du chantier : ' + chantier.statut,
        reason: 'chantier',
        created_by: this.sRoles.user.id,
        created_at: new Date(),
        caseId: this.chantier.caseId._id,
        customerId: this.case.customer,
        adv: this.case.adv._id,
      };
      this.sInfos.createInfo(comment).then((res) => {
        if (res['error']) {
          alert(res.error);
        }
      });
      this.sHistoriques
        .createHistorique(
          this.chantier.caseId,
          this.case.customer._id,
          reason,
          change,
          value,
          this.sRoles.user.id
        )
        .then((res) => {
          if (res['error']) {
            alert(res);
          }
        });
    }
  }
  showInfosByAdvId() {
    this.sInfos.getInfoByAdvId(this.sRoles.user.id).subscribe((res) => {
      this.infos = res;
      this.infos.forEach((element) => {
        if (element.vu) {
          this.infosRead.push(element);
        } else {
          this.infosUnread.push(element);
        }
      });
    });
  }
  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      this.onSubmitMultiple(title, 'ajouté', 'pièce', title);
    }
  }

  onSubmitMultiple(title, reason, change, value) {
    this.timeOutLoader = false;
    setTimeout(function () {
      this.timeOutLoader = true;
    }, 5000);
    const formData = new FormData();
    this.pieceForm.value.description += ' / ' + this.chantier.title;
    formData.append('title', title);
    formData.append('description', this.pieceForm.value.description);
    formData.append('qualification', this.pieceForm.value.qualification);
    formData.append('caseId', this.chantier.caseId._id);
    formData.append('created_at', this.pieceForm.value.created_at);
    formData.append('chantier', this.chantierId);
    for (let img of this.multipleImagesPieces) {
      formData.append('filesPieces', img);
    }

    this.http.post<any>(this.apiUrl + 'multiplePieces', formData).subscribe(
      (res) => {
        this.piecesOk = true;
        this.ShowPieceByCaseId();
      },
      (err) => console.log(err)
    );
    this.sHistoriques
      .createHistorique(
        this.chantier.caseId,
        this.case.customer._id,
        reason,
        change,
        value,
        this.sRoles.user.id
      )
      .then((res) => {
        if (res['error']) {
          alert(res);
        } else {
        }
      });
  }

  showComment() {
    this.showBar = !this.showBar;
  }

  goToDeDietrich(product: string) {
    switch (product) {
      case 'Pacae':
        window.open(
          'https://sav.dedietrich-thermique.fr/#family-287768',
          '_blank'
        );
        break;
      case 'Pacaa':
        window.open(
          'https://sav.dedietrich-thermique.fr/climatisations/clim_up',
          '_blank'
        );
        break;
      case 'BT':
        window.open(
          'https://sav.dedietrich-thermique.fr/#family-287773',
          '_blank'
        );
        break;
      default:
        break;
    }
  }
}
