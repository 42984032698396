<app-commentbar *ngIf="!mobile && !showPvList && caseId" [caseId]="chantier.caseId._id"></app-commentbar>

<img *ngIf="mobile" class="notification-tech" [routerLink]="['/infos-advs']"
  src="../../../assets/icons/notification.svg">
<p *ngIf="mobile" class="compteur-tech">
  <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>
<div class="marge" *ngIf="chantier">
  <button class="mt-5 pl-1" mat-button [routerLink]="['/technicien-list-chantier']"><img
      src="../../../assets/icons/retour.svg">Retour</button>

  <div *ngIf="!mobile" class="icons">
    <a class="nav-item nav-link" style="font-size:0.8rem;" (click)="toggleShowPieceList()">
      <img *ngIf="showPieceList" class="actif" src="../../../assets/bar-icons/pieces-active.svg">
      <img *ngIf="!showPieceList" src="../../../assets/bar-icons/pieces.svg">
    </a>
  </div>
  <div *ngIf="!mobile" class="icons docs">
    <a class="nav-item nav-link " style="font-size:0.8rem;" (click)="toggleShowPvList()">
      <img *ngIf="showPvList" class="actif " src="../../../assets/bar-icons/documents-active.svg">
      <img *ngIf="!showPvList" src="../../../assets/bar-icons/documents.svg">
    </a>
  </div>
  <div>
    <div class="info-customer">
      <div class="title"> {{chantier.caseId.title}}</div>
      <div class="name-num"> {{chantier.caseId.customer.name}}</div>
      <div class="commercial"> {{chantier.caseId.commercial.agency}} - {{chantier.caseId.commercial.username}}</div>
      <a style="text-decoration-line: underline;" class="col-9 pl-1" data-toggle="modal"
        data-target="#exampleModal">Voir les infos client</a>
    </div>
    <div *ngIf="user.role !='Responsable Technicien'" class="qualif-mairie" [ngClass]="{'attente':chantier.statut === 'En cours',
 'conforme':chantier.statut === 'Terminé',
 'nonconforme':chantier.statut === 'Annulé'
}">{{chantier.statut}} </div>
    <div *ngIf="user.role !='Responsable Technicien'">
      <mat-form-field class="example-full-width">
        <mat-label>Chef d'équipe</mat-label>
        <input matInput class="col-5" placeholder="Chef d'équipe" [value]="chantier.chef" [(ngModel)]="chantier.chef"
          (change)="saveChangeChef(chantier,chantier._id,'edité','le chef d\'équipe')">
      </mat-form-field>
      <p *ngIf="chantier.chef === ''" class="message-alert">Veuillez renseigner ce champ</p>
    </div>

    <a style="text-decoration-line: underline;" *ngIf="mobile && showPvList" (click)="showPvList = !showPvList">Voir les
      pièces</a>

    <div>
      <button *ngIf='showSavDeDietriechPacae' class="btn-secondarycrm" (click)="goToDeDietrich('Pacae')">Accéder au SAV De
        Dietrich PACAE</button>
      <button *ngIf='showSavDeDietriechPacaa' class="btn-secondarycrm" (click)="goToDeDietrich('Pacaa')">Accéder au SAV De
        Dietrich PACAA</button>
      <button *ngIf='showSavDeDietriechBt' class="btn-secondarycrm" (click)="goToDeDietrich('BT')">Accéder au SAV De
        Dietrich BT</button>
    </div>


    <mat-select *ngIf="user.role =='Responsable Technicien'" class="custom-select-state qualif-mairie mb-2" [ngClass]="{'attente':chantier.statut === 'En cours',
'conforme':chantier.statut === 'Terminé',
'nonconforme':chantier.statut === 'Annulé'
}" [(ngModel)]="chantier.statut"
      (selectionChange)="saveChangeState(chantier, chantier._id, 'edité','le statut du chantier')">
      <mat-option class="conforme" value="En cours">
        En cours
      </mat-option>
      <mat-option class="attente" value="Terminé">
        Terminé
      </mat-option>
      <mat-option class="nonconforme" value="Annulé">
        Annulé
      </mat-option>
    </mat-select>
    <div class="equipe" *ngIf="user.role =='Responsable Technicien'">
      <mat-form-field class="example-full-width mr-5">
        <mat-label>Chef d'équipe</mat-label>
        <input matInput class="col-lg-5 col-sm-3" placeholder="Chef d'équipe" [value]="chantier.chef"
          [(ngModel)]="chantier.chef" (change)="saveChangeChef(chantier,chantier._id,'edité','le chef d\'équipe')">
      </mat-form-field>
      <p *ngIf="chantier.chef === ''" class="message-alert">Veuillez renseigner ce champ</p>
      <mat-form-field *ngIf="user.role ==='Responsable Technicien'">
        <mat-label>Equipe</mat-label>
        <mat-select [(ngModel)]="chantier.tech[0]._id"
          (selectionChange)="saveChangeEquipe(chantier,chantier._id,'edité','l\'équipe du chantier')">
          <mat-option *ngFor="let tech of techs" [value]="tech._id">
            {{tech.username}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-checkbox [(ngModel)]="skipSignature" (change)="onCheckboxChange()">Désactiver Signature</mat-checkbox>

    </div>

  </div>
  <!-- POP UP -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal">X</button>
          <ul>
            <h3>Mr et Mme {{chantier.caseId.customer.name}}</h3>
            <section>
              <h4>Adresse</h4>
              <li>{{ chantier.caseId.customer.address }}</li>
              <li>{{chantier.caseId.customer.cp}} {{ chantier.caseId.customer.city }}</li>
            </section>
            <section>
              <h4>Numéros</h4>
              <li *ngIf='chantier.caseId.customer.mobile'>0{{ chantier.caseId.customer.mobile }}</li>
              <li *ngIf='chantier.caseId.customer.fixe'>0{{ chantier.caseId.customer.fixe }}</li>
              <li> <a href="mailto:{{chantier.caseId.customer.mail}}">{{chantier.caseId.customer.mail}}</a></li>
            </section>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!showPieceList && !showPvList">
  <div style="margin: 2% 0;"
    [ngClass]="vt ? 'col-xs-12 col-lg-10 col-sm-5 container' : 'col-xs-12 col-lg-10 col-sm-12 container container-height ' ">
    <h5>Informations complémentaires</h5>
    <textarea class="custom-textarea infos-size" placeholder="Aucune informations" [(ngModel)]="chantier.infos"
      readonly></textarea>
  </div>
  <div style="margin: 2% 0;"
    [ngClass]="vt ? 'col-xs-12 col-lg-10 col-sm-5 container' : 'col-xs-12 col-lg-10 col-sm-12 container container-height ' ">
    <h5>Débrief chantier</h5>
    <textarea class="custom-textarea" placeholder="Debrief" [value]="chantier.description"
      [(ngModel)]="chantier.description"
      (change)="saveChange(chantier,chantier._id,'edité','le débrief du chantier')"></textarea>
  </div>

  <button *ngIf="!mobile" (click)="goToAddPiece()" class="btn-secondarycrm"> Nouvelle Piece +</button>

  <div *ngIf="vt" class=" col-xs-12 col-lg-5 col-sm-5">
    <h5>Visite Technique</h5>
    <div class="card">
      <div class="card-content">
        <p data-toggle="tooltip" data-placement="top"> Description : {{ vt.description }} </p>
        <button class="btn-tertiarycrm" (click)="GoToDetailPiece(vt._id)">Voir La VT</button>
      </div>
    </div>
  </div>

  <button (click)="goToAddPiece()" *ngIf="mobile" class="btn-secondarycrm"> Nouvelle Piece +</button>

  <div style="margin: 0 1.5px ;" class="mt-5 mb-5 row" *ngIf="mobile">

    <div class="add-chantier">
      <button (click)="toggleShowPvList()" class="ml-2 btn-secondarycrm"> Document fin de chantier +</button>

    </div>

    <mat-card class="col-xs-12 col-lg-3 col-sm-5 mt-3" *ngFor="let piece of techPieces"
      (click)="GoToDetailPiece(piece._id)">
      <img [style.di] class="arrow" src="../../../assets/icons/arrow-right.svg">
      <mat-card-content [style.di]>
        <h6>{{ piece.title }} <em>{{ piece.description }}</em></h6>
        <p class="piece-statut" [ngClass]="{'attente':piece.qualification === 'En attente de qualification',
                        'conforme':piece.qualification === 'Conforme',
                        'nonconforme':piece.qualification === 'Non conforme'
                      }">{{ piece.qualification }}</p>

      </mat-card-content>
    </mat-card>
  </div>

</div>


<div *ngIf="showPieceList">
  <button (click)="goToAddPiece()" class="btn-secondarycrm"> Nouvelle Piece +</button>

  <div class=" container-piece row">
    <mat-card class="col-xs-12 col-lg-3 col-sm-3n " *ngFor="let piece of techPieces"
      (click)="GoToDetailPiece(piece._id)">
      <img src="../../../assets/pieces/img-piece.png" class="card-img-top" alt="pieces">
      <p class="qualif" [ngClass]="{'attente':piece.qualification === 'En attente de qualification',
                        'conforme':piece.qualification === 'Conforme',
                        'nonconforme':piece.qualification === 'Non conforme'
                      }">{{ piece.qualification }}</p>
      <mat-card-content>
        <p class="title">{{ piece.title }}</p>
        <h1 style="position: absolute;
              font-size: 0.7em;
              right: 2%;
              bottom: -2%;"> {{piece.created_at |date: 'short'}}</h1>
        <p class="description" *ngIf="piece.description && !piece.commentary">{{ piece.description }}</p>
        <p class="description" style="opacity:0;" *ngIf="piece.description === null">v</p>
        <p class="description" *ngIf="piece.commentary != ''">{{piece.commentary}}</p>
      </mat-card-content>
    </mat-card>

  </div>
</div>

<app-products-head [chantierId]='chantier' [case]="case" *ngIf="case && showPvList"></app-products-head>